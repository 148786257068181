<template>
  <div>
    <v-pagination :value="page"
                  :length="lastPage"
                  :prev-icon="'mdi-chevron-left'"
                  :next-icon="'mdi-chevron-right'"
                  :color="'#00863D'"
                  v-model="page"
                  @next="emitNext"
                  @previous="emitPrevious"
                  @input="emitInput"
                  dark
                  v-if="hasResults"
    />
    <h2 v-else>Nenhum resultado encontrado</h2>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    data() {
      return {
        page: 1,
        lastPage: 0,
        total: 0,
      };
    },
    computed: {
      hasResults() {
        return this.total > 0;
      },
    },
    methods: {
      emitInput(page) {
        this.$emit('input', page);
      },
      emitNext(page) {
        this.$emit('next', page);
      },
      emitPrevious(page) {
        this.$emit('previous', page);
      },
    },
  };
</script>

<style scoped>

</style>
