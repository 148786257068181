export const LOCAL_WEB_SERVICE_URL = "https://lanchebem.com";
export const HAL_URL = "http://localhost:5000/";

export const LOGIN = "pdv/login";
export const SEARCH_DEPENDENT = "pdv/accounts/search/";
export const SEARCH_CUSTOMER_AND_DEPENDENT =
  "pdv/accounts/customer-and-dependent/search";
export const CREDIT_PURCHASE = "pdv/accounts/credit/purchase";
export const SEARCH_PRODUCT = "pdv/products/search";
export const TOP_PRODUCTS = "pdv/products/top";
export const SUBMIT_SALE = "pdv/order/finish";

export const PRINT_SALE = "printer/print-pdv";
