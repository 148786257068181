export default {
  setDependent(context, user) {
    context.commit('SET_DEPENDENT', user);
  },
  addProduct(context, product) {
    context.commit('ADD_PRODUCT', product);
  },
  removeProduct(context, product) {
    context.commit('REMOVE_PRODUCT', product);
  },
  clearSale: (context) => {
    context.commit('CLEAR_SALE');
  },
  clearDependent: (context) => {
    context.commit('CLEAR_DEPENDENT');
  },
};
