<template>
  <loadable-area ref="page" class="mx-4 my-2">
    <v-row>
      <v-row cols="8" class="ml-4">
        <v-flex md12 class="ma-2">
          <v-text-field
            v-model="dependentName"
            :counter="50"
            append-icon="mid-search"
            label="Nome do Comprador"
            clear-icon="mdi-close-circle"
            clearable
            @input="writing"
            @click:clear="search"
            type="text"
            required
            ref="dependentNameSearchInput"
          />
        </v-flex>
        <v-flex md12 class="ma-2">
          <v-text-field
            v-model="customerName"
            :counter="50"
            append-icon="mid-search"
            label="Nome do Responsável"
            clear-icon="mdi-close-circle"
            clearable
            @input="writing"
            @click:clear="search"
            type="text"
            required
            ref="customerNameSearchInput"
          />
        </v-flex>
      </v-row>
      <v-spacer/>
      <v-flex md4 align-self-center class="mr-4">
        <v-btn :raised="true" color="#00863D" class="white--text btn-load d-flex" :disabled="isNoneDependentSelected"
               @click="openAddCreditModal()" :block="true" ref="withDependentBtn">
          Adicionar Crédito (F10)
        </v-btn>
        <alert-dialog :text="'Selecione um dependente'" ref="alertDialog"/>
      </v-flex>
    </v-row>
    <panel-box>
      <loadable-area ref="cardsList">
        <v-row>
          <v-flex xs6 md3 lg2 d-flex justify-center align-center class="my-2"
                  v-for="(dependent, index) in pagination.data">
            <dependent-card :dependent="dependent" @selected="selectDependent"
                            :ref="'dependentCard'+dependent.dependent_id"/>
          </v-flex>
        </v-row>
        <v-flex>
          <v-row>
            <pagination :page="pagination.page" :last-page="pagination.lastPage" :total="pagination.total"
                        v-show="canShowPagination" @input="search" ref="pagination"/>
          </v-row>
        </v-flex>
      </loadable-area>
    </panel-box>
    <add-credit-dialog @reload-search="search" @clear-search="clearSearch" ref="addCreditDialog"/>
    <alert-dialog ref="unauthenticatedDialog"/>
  </loadable-area>
</template>

<script>
  import PanelBox from "../components/PanelBox";
  import AlertDialog from "../components/AlertDialog";
  import DependentCard from "../components/DependentCard";
  import LoadableArea from "../components/LoadableArea";
  import Pagination from "../components/Pagination";
  import {mapActions, mapGetters} from "vuex";
  import {SEARCH_CUSTOMER_AND_DEPENDENT, SEARCH_DEPENDENT} from "../api-endpoints";
  import AddCreditDialog from "../components/AddCredit/AddCreditDialog";

  export default {
    name: "AddCreditSearch",
    components: {PanelBox, AlertDialog, DependentCard, LoadableArea, Pagination, AddCreditDialog},
    data() {
      return {
        pagination: window.basePagination(),
        dependentName: '',
        customerName: '',
        writingTemp: null,
        selectedDependent: null,
        canShowPagination: false,
      };
    },
    computed: {
      ...mapGetters({isAuthenticated: 'user/isAuthenticated'}),
      isNoneDependentSelected() {
        return this.selectedDependent === null;
      },
    },
    beforeMount() {
      if (this.isAuthenticated === false) {
        this.$router.replace('/');
      }
    },
    mounted() {
      this.$refs.dependentNameSearchInput.$el.querySelector('input').focus();
      this.$refs.page.stopLoading();
      this.$refs.cardsList.stopLoading();
      window.addEventListener('keydown', this.fireShortcut);
    },
    methods: {
      ...mapActions({
        setDependent: 'addCredit/setDependent',
        clearUser: 'user/clearUser',
      }),
      writing() {
        clearTimeout(this.writingTemp);
        this.writingTemp = setTimeout(() => {
          this.search(1);
        }, 1000);
      },
      clearSearch() {
        this.customerName = '';
        this.dependentName = '';
      },
      async search(page = 1) {
        this.canShowPagination = false;
        this.selectedDependent = null;
        this.pagination = window.basePagination();
        let customerName = '';
        let dependentName = '';
        if (this.customerName !== '') {
          customerName = `&customer_name=${this.customerName}`;
        }
        if (this.dependentName !== '') {
          dependentName = `&dependent_name=${this.dependentName}`;
        }
        this.$refs.cardsList.startLoading();
        try {
          const response = await window.axiosAuthenticated.get(
            `${SEARCH_CUSTOMER_AND_DEPENDENT}?page=${page}${customerName}${dependentName}`);
          await this.setPaginationResponse(response.data);
          if (this.$refs.pagination.hasResults === false) {
            return;
          }
          const firstDependentId = this.pagination.data[0].dependent_id;
          this.getCardComponent(firstDependentId).select();
        } catch (e) {
          try {
            window.handle401(e.response);
          } catch (unauthenticatedError) {
            this.logout(unauthenticatedError.message);
          }
        } finally {
          this.$refs.cardsList.stopLoading();
          this.canShowPagination = true;
        }
      },
      selectDependent(selectedDependent) {
        this.selectedDependent = selectedDependent;
        const dependentsToUnselect = this.pagination.data.filter((dependent) => {
          return dependent.dependent_id !== selectedDependent.dependent_id;
        });
        dependentsToUnselect.forEach((dependent) => {
          this.getCardComponent(dependent.dependent_id).unselect();
        });
      },
      selectNext() {
        if (this.isNoneDependentSelected) {
          return;
        }
        const nextDependentId = this.handleNextIdToSelect();
        const cardToSelect = this.getCardComponent(nextDependentId);
        cardToSelect.select();
      },
      handleNextIdToSelect() {
        const dependents = this.pagination.data;
        const currentIndex = dependents.findIndex(
          (dependent) => dependent.dependent_id === this.selectedDependent.dependent_id,
        );
        const lastIndex = dependents.length - 1;
        const isLastIndex = currentIndex === lastIndex;
        if (isLastIndex) {
          return dependents[0].dependent_id;
        }
        const nextIndex = currentIndex + 1;
        return dependents[nextIndex].dependent_id;
      },
      getCardComponent(dependentId) {
        return this.$refs[`dependentCard${dependentId}`][0];
      },
      setPaginationResponse(pagination) {
        this.$refs.pagination.page = pagination.current_page;
        this.$refs.pagination.lastPage = pagination.last_page;
        this.$refs.pagination.total = pagination.total;

        this.pagination.page = pagination.current_page;
        this.pagination.lastPage = pagination.last_page;
        this.pagination.data = pagination.data;
        this.pagination.total = pagination.total;
        this.pagination.perPage = pagination.per_page;
      },
      openAddCreditModal() {
        if (this.isNoneDependentSelected) {
          this.$refs.alertDialog.open();
          return;
        }
        this.setDependent(this.selectedDependent);
        this.$refs.addCreditDialog.open();
      },
      fireShortcut(event) {
        if (!this.$refs.alertDialog) {
          return;
        }

        if (this.$refs.alertDialog.isOpen) {
          this.$refs.alertDialog.close();
          return;
        }
        switch (event.key) {
          case 'F10':
            this.$refs.withDependentBtn.$el.click();
            return;
          case 'Tab':
            this.selectNext();
            return;
          default:
            return;
        }
      },
      logout(message) {
        this.$refs.unauthenticatedDialog.open(message);
        this.clearUser();
        this.$router.replace('/');
      },
    },
  }
</script>

<style scoped>

</style>
