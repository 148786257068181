<template>
  <div class="panel-box">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'PanelBox',
  };
</script>

<style scoped>
  .panel-box {
    box-shadow: #FFAC00 0 2px 4px 1px;
    background-color: white;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px 20px;
  }
</style>
