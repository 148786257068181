<template>
  <v-dialog v-model="isOpen" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">Cancelar Venda</v-card-title>
      <v-card-text>
        Deseja cancelar a venda e voltar para a busca por dependente?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-grey lighten-2" text @click="isOpen = false">Não, permanecer</v-btn>
        <v-btn color="red darken-1" text @click="cancelSale">Sim, retornar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'CancelSaleDialog',
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      cancelSale() {
        this.isOpen = false;
        this.$emit('cancelled');
      },
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      }
    },
  };
</script>

<style scoped>

</style>
