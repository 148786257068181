<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card>
      <v-card-text class="pa-2">
        <div class="d-flex">
          <v-alert :dense="true" :outlined="true" :type="type" class="my-0 flex-fill">
            {{ message }}
          </v-alert>
          <v-icon class="ma-2" @click="close">mdi-close</v-icon>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AlertDialog',
    props: {
      text: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'warning'
      },
      color: {
        type: String,
        default: '',
        required: false,
      }
    },
    data() {
      return {
        isOpen: false,
        message: ''
      }
    },
    mounted() {
      this.message = this.text;
    },
    methods: {
      open(message = null) {
        if (message !== null) {
          this.message = message;
        }
        return this.isOpen = true;
      },
      close() {
        return this.isOpen = false;
      }
    }
  };
</script>

<style scoped>

</style>
