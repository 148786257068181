export default {
  setUser(context, user) {
    context.commit("SET_USER", user);
  },
  setToken(context, token) {
    context.commit("SET_TOKEN", token);
  },
  clearUser: context => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    context.commit("CLEAR_USER");
  },
  clearToken: context => {
    context.commit("CLEAR_TOKEN");
  }
};
