<template>
  <loadable-area ref="cardsList" class="tabs-content-box">
    <div class="row">
      <div class="my-2 col-4 d-flex"
                v-for="(productRanking, index) in productsRanking">
          <product-card :product="productRanking.product" @add="addProductToCart"
                        @remove="removeProductFromCart" :ref="'topProductCard'+productRanking.product.id"/>
          <input type="hidden" :id="'topProductCardHidden' + productRanking.product.id" @click="updateCard(productRanking.product.id)"/>
        </div>
      </div>
  </loadable-area>
</template>

<script>
  import {TOP_PRODUCTS} from '../../api-endpoints';
  import {mapActions, mapGetters} from 'vuex';
  import LoadableArea from '../../components/LoadableArea';
  import PanelBox from '../../components/PanelBox';
  import ProductCard from './ProductCard';

  export default {
    name: 'TopProductsList',
    components: {LoadableArea, PanelBox, ProductCard},
    data() {
      return {
        productsRanking: [],
        selectedProduct: null,
      };
    },
    computed: {
      ...mapGetters({hasDependent: 'sale/hasDependent', getDependent: 'sale/getDependent'}),
      isNoneDependentSelected() {
        return this.selectedProduct === null;
      },
    },
    async mounted() {
      await this.fetchProducts();
    },
    methods: {
      ...mapActions({addProduct: 'sale/addProduct', removeProduct: 'sale/removeProduct'}),
      async fetchProducts() {
        try {
          const routeParams = this.hasDependent ? '/' + this.getDependent.dependent_id : '';
          const response = await window.axiosAuthenticated.get(TOP_PRODUCTS + routeParams);
          this.productsRanking = response.data;
        } catch (e) {
          try {
            window.handle401(e.response);
          } catch (unauthenticatedError) {
            this.$parent.logout(unauthenticatedError.message);
          }
        }
        this.$refs.cardsList.stopLoading();
      },
      addProductToCart(product) {
        this.addProduct(product);
        this.updateCard(product.id);
        this.$emit('products-changed');
      },
      removeProductFromCart(product) {
        this.removeProduct(product);
        this.updateCard(product.id);
        this.$emit('products-changed');
      },
      getCardComponent(productId) {
        return this.$refs[`topProductCard${productId}`][0];
      },
      updateCard(productId) {
        this.getCardComponent(productId).setProductQuantity();
      }
    },
  };
</script>

<style scoped>
  .tabs-content-box {
    min-height: 700px;
  }
</style>
