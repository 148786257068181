<template>
  <loadable-area ref="salePage" class="mx-4 my-2">
    <v-row>
      <v-flex md7 class="pa-2">
        <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="#00863D"
          dark>
          <v-tab href="#tab-top-sales">Mais Vendidos</v-tab>
          <v-tab href="#tab-search">Procurar</v-tab>
        </v-tabs>
        <panel-box class="sale-panel-box">
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-top-sales'">
              <top-products-list @products-changed="updateCart"/>
            </v-tab-item>
            <v-tab-item :value="'tab-search'">
              <product-search @products-changed="updateCart"/>
            </v-tab-item>
          </v-tabs-items>
        </panel-box>
      </v-flex>
      <v-flex md5 class="pa-2">
        <sale-panel ref="salePanel" @confirmed="submitSale" @cancelled="clearAndRedirect"/>
        <sale-products ref="saleProducts" @products-changed="updateCart"/>
      </v-flex>
    </v-row>
    <alert-dialog :type="'error'" :color="'red darken-4'" ref="alertDialog"/>
    <print-sale @finished="clearAndRedirect" ref="printSale"/>
  </loadable-area>
</template>

<script>
  import LoadableArea from '../components/LoadableArea';
  import ProductSearch from '../components/Sale/ProductSearch';
  import SalePanel from '../components/Sale/SalePanel';
  import SaleProducts from '../components/Sale/SaleProducts';
  import {mapActions, mapGetters} from 'vuex';
  import TopProductsList from '../components/Sale/TopProductsList';
  import PanelBox from '../components/PanelBox';
  import {SUBMIT_SALE} from '../api-endpoints';
  import AlertDialog from '../components/AlertDialog';
  import PrintSale from '../components/Sale/PrintSale';

  export default {
    name: 'Sale',
    components: {PrintSale, TopProductsList, SaleProducts, SalePanel, ProductSearch, LoadableArea, PanelBox, AlertDialog},
    data() {
      return {
        tab: null,
        sessionId: null,
      };
    },
    computed: {
      ...mapGetters({
        getUser: 'user/getUser',
        getDependent: 'sale/getDependent',
        hasDependent: 'sale/hasDependent',
      }),
    },
    async beforeMount() {
      if (await this.isAuthenticated() === false) {
        await this.$router.replace('/');
      }
    },
    mounted() {
      this.sessionId = `${Date.now()}-${this.getUser.id}`;
      this.updateCart();
      this.$refs.salePage.stopLoading();
      window.addEventListener('keydown', this.fireShortcut);
    },
    methods: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
      ...mapActions({
        clearSale: 'sale/clearSale',
        clearUser: 'user/clearUser',
      }),
      updateCart() {
        this.$refs.saleProducts.updateCart();
        this.$refs.salePanel.update();
      },
      async submitSale() {
        try {
          const sale = this.saleForm();
          await window.axiosAuthenticated.post(SUBMIT_SALE, this.saleForm());
          this.$refs.printSale.open(sale);
        } catch (e) {
          try {
            window.handle401(e.response);
          } catch (unauthenticatedError) {
            this.$parent.logout(unauthenticatedError.message);
            return;
          }
          if (e.response.data !== undefined) {
            this.$refs.alertDialog.open(e.response.data.message);
          }
        } finally {
          this.$refs.salePanel.sendingSale = false;
        }
      },
      saleForm() {
        return {
          dependent_id: this.hasDependent ? this.getDependent.dependent_id : null,
          items: this.$refs.saleProducts.cart,
          amount: this.$refs.saleProducts.total,
          session_id: this.sessionId,
        };
      },
      clearAndRedirect() {
        this.clearSale();
        this.$router.replace('/dependent-search');
      },
      fireShortcut(event) {
        const salePanel = this.$refs.salePanel;

        if (!this.$refs.alertDialog) {
          return;
        }

        if (salePanel.isAnyDialogOpen() || this.$refs.alertDialog.isOpen) {
          salePanel.closeDialogs();
          this.$refs.alertDialog.close();
          return;
        }
        switch (event.key) {
          case 'Escape':
            salePanel.openCancelDialog();
            return;
          case 'Enter':
            salePanel.openConfirmationDialog();
            return;
          default:
            return;
        }
      },
      logout(message) {
        this.$refs.unauthenticatedDialog.open(message);
        this.clearUser();
        this.clearSale();
        this.$router.replace('/');
      },
    },
  };
</script>

<style scoped>
  .sale-panel-box {
    overflow: scroll;
  }

  @media (max-height: 800px) {
    .sale-panel-box {
      max-height: 73vh;
    }
  }

  @media (min-height: 801px) {
    .sale-panel-box {
      max-height: 82vh;
    }
  }
</style>
