<template>
  <v-dialog persistent v-model="isOpen" :max-width="'500px'">
    <v-card>
      <v-card-title>
        Venda Autorizada
      </v-card-title>
      <v-card-text class="text-center">
        <template v-if="finished">
          Venda realizada com sucesso!
        </template>
        <template v-else>
          <template v-if="isSending">
            Enviado dados da venda para impressora...
            <v-progress-linear color="#FFAC00" :indeterminate="true"/>
          </template>
          <template v-else>
            Não foi possível realizar a impressão
          </template>
        </template>
      </v-card-text>
      <v-card-actions v-if="!isSending && !finished">
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Finalizar Sem Impressão</v-btn>
        <v-btn color="green darken-1" text @click="submitSaleToPrinter">Tentar Novamente</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import {PRINT_SALE} from '../../api-endpoints';
  import {mapGetters} from 'vuex';

  export default {
    name: 'PrintSale',
    components: {},
    data() {
      return {
        isOpen: false,
        isSending: true,
        sale: null,
        finished: false
      };
    },
    computed: {
      ...mapGetters({hasDependent: 'sale/hasDependent', getDependent: 'sale/getDependent', getUser: 'user/getUser'}),
      hasSale() {
        return this.sale !== null;
      },
    },
    mounted() {

    },
    methods: {
      async submitSaleToPrinter() {
        this.isSending = true;
        const form = this.makeForm();
        try {
          await window.axiosHAL.post(PRINT_SALE, form);
          this.finished = true;
          setTimeout(() => this.$emit('finished'), 3000);
        } catch (e) {
        } finally {
          this.isSending = false;
        }
      },
      open(sale) {
        this.sale = sale;
        if (this.hasSale === false) {
          return;
        }
        this.isOpen = true;
        this.submitSaleToPrinter();
      },
      close() {
        this.finished = true;
        setTimeout(() => {
          this.isOpen = false;
          this.$emit('finished');
        }, 3000);
      },
      makeForm() {
        return {
          CustomerName: this.hasDependent ? this.getDependent.dependent_name : '',
          CompanyName: this.getUser.place_name,
          Amount: this.sale.amount,
          Products: this.sale.items,
          Date: this.getDate(),
        };
      },
      getDate(){
        const timezoneOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        return (new Date(Date.now() - timezoneOffset)).toISOString().slice(0, -1);
      }
    },

  };
</script>

<style scoped>

</style>
