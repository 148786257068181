import Vuex from 'vuex';
import user from './modules/user';
import sale from './modules/sale';
import addCredit from './modules/add-credit';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {user, sale, addCredit},
  plugins: [
    createPersistedState({ storage: window.sessionStorage })
  ],
});
