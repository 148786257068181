<template>
  <v-container>
    <v-layout>
      <v-row>
        <v-flex xs12 md4 d-flex justify-center align-center class="my-2">
          <v-img alt="Lanche Bem" src="../assets/logo.png" max-width="200" contain/>
        </v-flex>
        <v-flex xs12 md8>
          <login @authenticated="logUserIn"/>
        </v-flex>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
  // @ is an alias to /src
  import Login from '../components/Login';
  import {mapGetters} from 'vuex';

  export default {
    name: 'home',
    components: {Login},
    async beforeMount() {
      if (await this.isAuthenticated()) {
        await this.$router.replace('/dependent-search');
      }
    },
    methods: {
      ...mapGetters({isAuthenticated: 'user/isAuthenticated'}),
      logUserIn() {
        window.updateAxios();
        this.$router.replace('/dependent-search');
      },
    },
  };
</script>
