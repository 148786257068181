<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <td>Produto</td>
          <td>Preço</td>
          <td>Quantidade</td>
          <td/>
        </tr>
        </thead>
        <tbody>
        <tr v-for="product in cart">
          <td>{{ product.name}}</td>
          <td>{{ formatToReal(product.price) }}</td>
          <td class="text-center">{{ product.quantity}}</td>
          <td>
            <v-row class="justify-space-around">
              <v-btn color="red darken-4" class="white--text icon-button" rounded raised
                     @click="removeProductFromCart(product)">
                <v-icon dark>mdi-minus-circle</v-icon>
              </v-btn>
              <v-btn color="primary" class="white--text icon-button" rounded raised @click="addProductToCart(product)"
                     :disabled="product.remaining === 0">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-row>
          </td>
        </tr>
        <tr>
          <td>
            Total
          </td>
          <td colspan="2">
            <strong class="total-price" :class="{'red-text': textRed, 'green-text': textGreen}">{{ formatToReal(total)
              }}</strong>
            <img class="coin" :class="{ up: showCoin}" src="../../assets/coin.png" alt="coin"
                 ref="coin" :width="24" :height="24"/>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import PanelBox from '../PanelBox';

  export default {
    name: 'SaleProducts',
    components: {PanelBox},
    data() {
      return {
        cart: [],
        showCoin: false,
        textGreen: false,
        textRed: false,
      };
    },
    computed: {
      total() {
        return this.cart.reduce((a, b) => {
          const itemTotalPrice = b.price * b.quantity;
          return a + itemTotalPrice;
        }, 0);
      },
    },
    mounted() {
      this.updateCart();
    },
    methods: {
      ...mapGetters({getProducts: 'sale/getProducts'}),
      ...mapActions({addProduct: 'sale/addProduct', removeProduct: 'sale/removeProduct'}),
      updateCart() {
        const oldCartTotal = this.total;
        let groupedProducts = this.getProducts().reduce((groupedProducts, product) => {
          groupedProducts[product.id] = [...groupedProducts[product.id] || [], product];
          return groupedProducts;
        }, {});

        groupedProducts = Object.entries(groupedProducts).map(groupedProduct => groupedProduct[1]);

        this.cart = groupedProducts.map(groupedProduct => {
          const quantityInSale = groupedProduct.length;
          return {
            product: groupedProduct[0].id,
            name: groupedProduct[0].name,
            price: groupedProduct[0].price,
            quantity: quantityInSale,
            remaining: groupedProduct[0].remaining_stock - quantityInSale,
            originalProduct: groupedProduct[0],
          };
        });

        if (oldCartTotal > this.total) {
          this.textRed = true;
        }
        if (oldCartTotal < this.total) {
          this.textGreen = true;
          this.showCoin = true;
        }

        setTimeout(() => {
          this.showCoin = false;
          this.textGreen = false;
          this.textRed = false;
        }, 250);
      },
      formatToReal(value) {
        return window.formatToReal(value);
      },
      addProductToCart(product) {
        if (product.remaining - 1 < 0) {
          this.$emit('insufficient', this.product);
          return;
        }
        this.addProduct(product.originalProduct);
        this.updateCards(product.product);
        this.$emit('products-changed');
      },
      removeProductFromCart(product) {
        this.removeProduct(product.originalProduct);
        this.updateCards(product.product);
        this.$emit('products-changed');
      },
      updateCards(productId) {
        const topProductsCard = document.querySelector(`#topProductCardHidden${productId}`);
        if (topProductsCard !== null) {
          topProductsCard.click();
        }
        const productSearchCard = document.querySelector(`#productSearchCardHidden${productId}`);
        if (productSearchCard !== null) {
          productSearchCard.click();
        }
      },
    },
  };
</script>

<style scoped>
  .total-price {
    font-size: 1.75rem;
  }

  .coin {
    position: absolute;
    margin-left: -50px;
    transition: .25s ease-in;
    -webkit-transition: .25s ease-in;
    -moz-transition: .25s ease-in;
    -ms-transition: .25s ease-in;
    -o-transition: .25s ease-in;
    opacity: 0;
  }

  .up {
    transform: translateY(-100px);
    opacity: 1;
  }

  .red-text {
    color: red;
    transition: .25s color;
    -webkit-transition: .25s color;
    -moz-transition: .25s color;
    -ms-transition: .25s color;
    -o-transition: .25s color;
  }

  .green-text {
    color: #00863D;
    transition: .25s color;
    -webkit-transition: .25s color;
    -moz-transition: .25s color;
    -ms-transition: .25s color;
    -o-transition: .25s color;
  }

  .icon-button {
    min-width: 36px !important;
    padding: 0 !important;
  }
</style>
