import axios from 'axios';
import {HAL_URL, LOCAL_WEB_SERVICE_URL} from './api-endpoints';
import {ExtendableError} from './extendableError';

window.updateAxios = () => {
  if (JSON.parse(sessionStorage.getItem('vuex')) === null) {
    sessionStorage.setItem('vuex', JSON.stringify({user: {}}));
  }

  const user = JSON.parse(sessionStorage.getItem('vuex')).user

  window.axiosAuthenticated = axios.create({
    baseURL: LOCAL_WEB_SERVICE_URL,
    timeout: 20000,
    withCredentials: false,
    headers: {
      'place-id':user.user.place_id,
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' +
        user.token,
    },
  });
};

window.axiosUnauthenticated = axios.create({
  baseURL: LOCAL_WEB_SERVICE_URL,
  timeout: 20000,
  withCredentials: false,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

window.axiosHAL = axios.create({
  baseURL: HAL_URL,
  timeout: 20000,
  withCredentials: false,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

class UnauthenticatedError extends ExtendableError {
}

window.handle401 = response => {
  if (response === undefined) return;
  if (response.status === 401) {
    throw new UnauthenticatedError(
      'Sessão expirada, realize o login novamente');
  }
};

window.onload = () => {
  window.updateAxios();
};
