<template>
  <v-container class="tabs-content-box">
    <v-row>
      <v-flex md12>
        <v-text-field
          v-model="productName"
          :counter="50"
          append-icon="mid-search"
          label="Nome"
          clear-icon="mdi-close-circle"
          clearable
          @input="writing"
          @click:clear="search"
          type="text"
          required
          ref="searchInput"
        />
      </v-flex>
    </v-row>
    <loadable-area ref="cardsList">
        <div class="row">
          <div class="my-2 col-md-4 d-flex"
                  v-for="(product, index) in pagination.data">
            <product-card :product="product" @add="addProductToCart"
                          @remove="removeProductFromCart" :ref="'productSearchCard'+product.id"/>
            <input type="hidden" :id="'productSearchCardHidden' + product.id" @click="updateCard(product.id)"/>
          </div>
        </div>

        <v-row>
          <v-flex>
            <pagination :page="pagination.page" :last-page="pagination.lastPage" :total="pagination.total"
                        v-show="canShowPagination" @input="search" ref="pagination"/>
          </v-flex>
        </v-row>
    </loadable-area>
  </v-container>
</template>

<script>
  import {SEARCH_PRODUCT} from '../../api-endpoints';
  import Pagination from '../../components/Pagination';
  import {mapActions, mapGetters} from 'vuex';
  import LoadableArea from '../../components/LoadableArea';
  import ProductCard from './ProductCard';

  export default {
    name: 'ProductSearch',
    components: {Pagination, LoadableArea, ProductCard},
    data() {
      return {
        pagination: window.basePagination(),
        productName: null,
        writingTemp: null,
        selectedProduct: null,
        canShowPagination: false,
      };
    },
    computed: {
      isNoneDependentSelected() {
        return this.selectedProduct === null;
      },
    },
    mounted() {
      this.$refs.cardsList.stopLoading();
    },
    methods: {
      ...mapActions({
        addProduct: 'sale/addProduct',
        removeProduct: 'sale/removeProduct',
      }),
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
        hasDependent: 'sale/hasDependent',
        getDependent: 'sale/getDependent',
      }),
      writing() {
        clearTimeout(this.writingTemp);
        this.writingTemp = setTimeout(() => {
          this.search(1);
        }, 1000);
      },
      async search(page = 1) {
        this.pagination = window.basePagination();
        this.selectedProduct = null;
        if (this.productName === '') {
          return;
        }
        this.$refs.cardsList.startLoading();
        this.canShowPagination = false;
        try {
          const routeParams = this.hasDependent() ? '/' + this.getDependent().dependent_id : '-all';
          const response = await window.axiosAuthenticated.get(
            SEARCH_PRODUCT + routeParams,
            {
              params: {
                page: page,
                term: this.productName,
              },
            },
          );
          await this.setPaginationResponse(response.data);
          if (this.$refs.pagination.hasResults === false) {
            return false;
          }
        } catch (e) {
          try {
            window.handle401(e.response);
          } catch (unauthenticatedError) {
            this.$parent.logout(unauthenticatedError.message);
          }
        } finally {
          this.canShowPagination = false;
          this.$refs.cardsList.stopLoading();
        }
      },
      setPaginationResponse(pagination) {
        this.$refs.pagination.page = pagination.current_page;
        this.$refs.pagination.lastPage = pagination.last_page;
        this.$refs.pagination.total = pagination.total;

        this.pagination.page = pagination.current_page;
        this.pagination.lastPage = pagination.last_page;
        this.pagination.data = pagination.data;
        this.pagination.total = pagination.total;
        this.pagination.perPage = pagination.per_page;
      },
      addProductToCart(product) {
        this.addProduct(product);
        this.updateCard(product.id);
        this.$emit('products-changed');
      },
      removeProductFromCart(product) {
        this.removeProduct(product);
        this.updateCard(product.id);
        this.$emit('products-changed');
      },
      getCardComponent(productId) {
        return this.$refs[`productSearchCard${productId}`][0];
      },
      updateCard(productId) {
        this.getCardComponent(productId).setProductQuantity();
      }
    },
  };
</script>

<style scoped>
  .tabs-content-box {
    min-height: 700px;
  }
</style>
