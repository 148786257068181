<template>
  <v-dialog v-model="isOpen" persistent max-width="500">
    <v-card class="text-center">
      <v-card-title class="headline justify-center">Adicionar Crédito</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text class="justify-center mt-9 padding-card">
          <v-img v-if="getDependent.dependent_img" :src="getDependent.dependent_img" :height="240" :contain="true"/>
          <v-img v-else src="../../assets/logo.png" :height="240" contain/>
          <v-flex>
            <div class="col-12">
              <div class="row">
                <span class="black--text mt-2 font-medium" v-if="hasDependent"><strong>Comprador:</strong> {{ getDependent.dependent_name}}</span>
              </div>
              <div class="row">
                <span class="black--text font-medium" v-if="hasDependent"><strong>Responsável:</strong> {{ getDependent.customer_name}}</span>
              </div>
              <div class="row">
                <span class="black--text font-medium"><strong>Saldo Atual:</strong> {{ getTotalFormatted }}</span>
              </div>
              <div class="row d-flex justify-center">
                <div class="col-8">
                  <v-text-field
                    ref="price"
                    v-model.lazy="price" v-money="money"
                    label="Valor"
                    :rules="[rules.required]"
                    @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-flex>
        </v-card-text>
      </v-form>
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="green darken-1" text @click="confirmSale">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
    <loading-credit-buy ref="loadingCredit"></loading-credit-buy>
    <alert-dialog type="success" ref="alertDialog"/>
  </v-dialog>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {VMoney} from 'v-money'
  import {CREDIT_PURCHASE, SEARCH_CUSTOMER_AND_DEPENDENT} from "../../api-endpoints";
  import LoadingCreditBuy from "./LoadingCreditBuy";
  import AlertDialog from "../AlertDialog";

  export default {
    name: 'AddCreditDialog',
    components: {VMoney, LoadingCreditBuy, AlertDialog},
    data() {
      return {
        rules: {
          required: value => {
            let amount = value.replace(/^\D+/g, '');
            amount = parseFloat(amount.replace(',', '.'));
            return !!amount || 'É necessário informar um valor';
          },
        },
        valid: true,
        isOpen: false,
        max: 20,
        price: '0',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false,
        },
      };
    },
    directives: {money: VMoney},
    computed: {
      ...mapGetters({hasDependent: 'addCredit/hasDependent', getDependent: 'addCredit/getDependent'}),
      getTotalFormatted() {
        return window.formatToReal(this.getDependent.balance);
      }
    },
    methods: {
      async confirmSale() {
        try {
          const form = this.makeForm();
          if (form.amount <= 0) {
            this.$refs.form.validate();
            return;
          }
          this.isOpen = false;
          this.$refs.loadingCredit.open();
          const response = await window.axiosAuthenticated.post(
            CREDIT_PURCHASE, form);
          setTimeout(() => {
            this.$refs.loadingCredit.close();
            setTimeout(() => {
              this.$refs.alertDialog.open('Crédito adicionado com sucesso!');
              this.$emit('clear-search');
              this.$emit('reload-search');
            }, 1000);
          }, 2000);
        } catch (e) {
          this.$refs.loadingCredit.close();
          this.$refs.alertDialog.open('Falha ao tentar adicionar créditos');
        }
      },
      open() {
        this.$nextTick(() => {
          if (this.$refs.price !== undefined) {
            this.price = 0;
            this.$refs.price.$el.getElementsByTagName('input')[0].value = 0;
            this.$refs.form.resetValidation();
          }
        });
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
      makeForm() {
        let amount = this.price;
        amount = amount.replace(/^\D+/g, '');
        amount = parseFloat(amount.replace(',', '.'));
        return {
          dependent_id: this.getDependent.dependent_id,
          amount: amount,
        };
      },
    },
  };
</script>

<style scoped>

  .font-medium {
    font-size: medium;
  }

  .padding-card {
    padding: 0px 24px 10px;;
  }

</style>
