<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "LoadingCreditBuy",
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
      open(message = null) {
        if (message !== null) {
          this.message = message;
        }
        return this.dialog = true;
      },
      close() {
        return this.dialog = false;
      }
    },
  }
</script>

<style scoped>

</style>
