export default {
  SET_DEPENDENT: (state, dependent) => {
    state.dependent = dependent;
  },
  ADD_PRODUCT: (state, product) => {
    state.products.push(product);
  },
  REMOVE_PRODUCT: (state, product) => {
    const indexToRemove = state.products.findIndex(cartProduct => {
      return cartProduct.id === product.id;
    });
    state.products = state.products.filter((cartProduct, index) => {
      return indexToRemove !== index;
    });
  },
  CLEAR_SALE: (state) => {
    state.dependent = null;
    state.products = [];
  },
  CLEAR_DEPENDENT: (state) => {
     state.dependent = null;
  },
};
