export default {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  CLEAR_USER: (state) => {
    state.user = {};
    state.token = null;
  },
  CLEAR_TOKEN: (state) => {
    state.token = null;
  },
};
