<template>
  <v-dialog v-model="isOpen" persistent max-width="300" @keydown.enter="confirmSaleEnter">
    <v-card class="text-center">
      <v-card-title class="headline justify-center">Confirmar Venda</v-card-title>
      <v-card-text class="justify-center">
        <v-img :src="getDependent.dependent_img" v-if="hasDependent" :width="240" :height="240"/>
        <h3 class="black--text mt-2" v-if="hasDependent">Confirmar venda para o cliente {{ getDependent.dependent_name}}?</h3><br>
        <h3 class="black--text">Total</h3>
        <h2 class="black--text">{{ getTotalFormatted }}?</h2>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="isOpen = false">Cancelar</v-btn>
        <v-btn color="green darken-1" text @click="confirmSale">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'ConfirmSaleDialog',
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      ...mapGetters({hasDependent: 'sale/hasDependent', getDependent: 'sale/getDependent', getTotal: 'sale/getTotal'}),
      getTotalFormatted() {
        return window.formatToReal(this.getTotal);
      }
    },
    methods: {
      confirmSale() {
        this.close();
        this.$emit('confirmed');
      },
      // por algum motivo, quando usando o enter so fecha assim
      confirmSaleEnter() {
        this.open();
        this.$emit('confirmed');
      },
      open() {
        this.isOpen = true;
      },
      close() {
        this.isOpen = false;
      },
    },
  };
</script>

<style scoped>

</style>
