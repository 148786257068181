import Vue from 'vue';
import VueRouter from 'vue-router';
import DependentSearch from '../views/DependentSearch';
import Home from '../views/Home';
import Sale from '../views/Sale';
import Config from '../views/Config';
import AddCreditSearch from "../views/AddCreditSearch";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/dependent-search',
    name: 'dependent-search',
    component: DependentSearch,
  },
  {
    path: '/add-credit-search',
    name: 'add-credit-search',
    component: AddCreditSearch,
  },
  {
    path: '/sale',
    name: 'sale',
    component: Sale,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/About'),
  },

  {
    path: '/config',
    name: 'config',
    component: Config,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


export default router;
