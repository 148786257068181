export default {
  getUser: state => {
    return state.user
  },
  getToken: state => {
    return state.token
  },
  isAuthenticated: state => {
    return state.token !== null;
  }
}
