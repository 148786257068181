<template>
  <v-flex md12 class="mb-2">
    <v-btn color="red darken-4" raised dark class="mb-2" :block="true" @click="openCancelDialog" ref="cancelBtn">
      <v-icon class="mr-1 mt-1">mdi-keyboard-return</v-icon>
      Cancelar e Retornar (Esc)
    </v-btn>
    <v-progress-linear :indeterminate="true" color="#00863D" v-if="sendingSale"/>
    <v-btn color="#00863D" raised :block="true" :disabled="btnSaleDisabled" @click="openConfirmationDialog" ref="saleBtn"
           class="white--text">
      <v-icon left>mdi-cash-usd</v-icon>
      Finalizar compra (Enter)
    </v-btn>
    <cancel-sale-dialog ref="cancelSaleDialog" @cancelled="cancelSale" />
    <confirm-sale-dialog ref="confirmSaleDialog" @confirmed="confirmSale"/>
    <div v-if="hasDependent" class="my-2 ml-2 mr-2 font-weight-bold row justify-space-around">
      <div>Cliente: {{ dependentName }}</div>
      <div>Saldo: {{ dependentBalance }}</div>
    </div>
  </v-flex>
</template>

<script>
  import {mapGetters} from 'vuex';
  import ConfirmSaleDialog from './ConfirmSaleDialog';
  import CancelSaleDialog from './CancelSaleDialog';

  export default {
    name: 'SalePanel',
    components: {CancelSaleDialog, ConfirmSaleDialog},
    data() {
      return {
        noProducts: true,
        sendingSale: false,
        dependentName: '',
        dependentBalance: 0,
      };
    },
    computed: {
      ...mapGetters({hasAnyProduct: 'sale/hasAnyProduct', hasDependent: 'sale/hasDependent', getDependent: 'sale/getDependent'}),
      btnSaleDisabled() {
        return this.noProducts || this.sendingSale;
      },
    },
    mounted() {
      this.update();
      this.dependentName = this.hasDependent ? this.getDependent.dependent_name : '';
      this.dependentBalance = this.hasDependent ? window.formatToReal(this.getDependent.balance) : 0;
    },
    methods: {
      update() {
        this.noProducts = this.hasAnyProduct === false;
      },
      openCancelDialog() {
        this.$refs.cancelSaleDialog.open();
      },
      openConfirmationDialog() {
        this.$refs.confirmSaleDialog.open();
      },
      confirmSale() {
        this.sendingSale = true;
        this.$emit('confirmed');
      },
      cancelSale() {
        this.$emit('cancelled');
      },
      isAnyDialogOpen() {
        return this.$refs.cancelSaleDialog.isOpen ||  this.$refs.confirmSaleDialog.isOpen;
      },
      closeDialogs() {
        return this.$refs.cancelSaleDialog.close() ||  this.$refs.confirmSaleDialog.close();
      }
    },
  };
</script>

<style scoped>

</style>
