import { required, email, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "O campo é obrigatório"
});

extend("min", {
  ...max,
  message: "Digite pelo menos {length} caracteres"
});

extend("max", {
  ...max,
  message: "O limite é de {length} caracteres"
});

extend("email", {
  ...email,
  message: "Digite um e-mail válido"
});
