<template>
  <v-app>
    <v-app-bar
      app
      :color="'#00863D'"
      dark>
      <v-flex :class="isAuthenticated ? 'xs2' : 'xs0'">
      </v-flex>
      <v-flex class="d-flex justify-center app-title lanche-bem-font" :class="isAuthenticated ? 'xs10' : 'xs12'">
        Lanche Bem - Ponto de Venda
      </v-flex>
      <v-btn text @click="searchDependents" v-if="isAuthenticated">
        Procurar Dependentes
      </v-btn>
      <v-btn text @click="addCredit" v-if="isAuthenticated">
        Adicionar Créditos
      </v-btn>
      <v-flex class="d-flex justify-end" :class="isAuthenticated ? 'xs2' : 'xs0'" v-if="isAuthenticated">
        Encerrar Sessão
        <v-icon right @click="logout">mdi-logout-variant</v-icon>
      </v-flex>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer
      app
      :color="'rgba(255,172,0,1)'"
      dark
    />
  </v-app>
</template>

<script>

  import {mapActions, mapGetters} from 'vuex';

  export default {
    name: 'App',

    data: () => ({}),
    computed: {
      ...mapGetters({
        isAuthenticated: 'user/isAuthenticated',
      }),
    },
    methods: {
      ...mapActions({
        clearUser: 'user/clearUser',
        clearSale: 'sale/clearSale',
      }),
      addCredit() {
        this.$router.replace('/add-credit-search');
      },
      searchDependents() {
        this.$router.replace('/dependent-search');
      },
      logout() {
        this.clearUser();
        this.clearSale();
        this.$router.replace('/');
      },
    },
  };
</script>
<style lang="css" scoped>
  @import url('https://fonts.googleapis.com/css?family=Anton&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Bitter&display=swap');

  * {
    font-family: 'Bitter', serif;
  }

  .lanche-bem-font{
    font-family: 'Anton', sans-serif !important;
  }

  .app-title {
    font-size: 1.75rem;
  }

</style>
