<template>
  <div>
    <v-progress-circular
      class="loader"
      v-if="loading"
      :class="loadingVisibility"
      :color="'#00863D'"
      :indeterminate="true"
      :size="64"
    />
    <template :class="contentVisibility">
      <slot/>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'LoadableArea',
    data() {
      return {
        loading: true
      }
    },
    computed: {
      loadingVisibility() {
        return this.loading ? 'show-loadable-area' : 'hide-loadable-area';
      },
      contentVisibility() {
        return this.loading ? 'hide-loadable-area' : 'show-loadable-area';
      },
    },
    methods: {
      startLoading() {
        this.loading = true;
      },
      stopLoading() {
        this.loading = false;
      }
    }
  };
</script>

<style scoped>
  .show-loadable-area {
    display: flex;
  }

  .hide-loadable-area {
    display: none;
  }

  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
</style>
