<template>
  <v-card outlined :id="'card-'+dependent.dependent_id" @click="select(dependent)" :raised="isSelected"
          :class="cardStyle">
    <v-card-title class="d-block">
      <h4 class="text-center v-text-field--full-width">{{ dependent.dependent_name }}</h4>
      <h6 class="text-center">{{ dependent.customer_name }}</h6>
    </v-card-title>
    <v-card-text>
      <v-img v-if="dependent.dependent_img" :src="dependent.dependent_img" :width="240" :height="240" />
      <v-img v-else src="../assets/logo.png" :width="240" :height="240" contain/>
      <div class="row mt-2 ml-1 mr-1 d-flex justify-space-between">
        <div>
          {{ dependent.age }} anos <br>
          {{ dependent.grade}}
        </div>
        <div>{{ getTotalFormatted }}</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'DependentCard',
    props: {
      dependent: {
        type: Object,
        default: {
          dependent_id: null,
          dependent_name: null,
          dependent_img: null,
          age: null,
          grade: null,
          balance: 0
        },
      },
    },
    data() {
      return {
        isSelected: false,
      };
    },
    computed: {
      cardStyle() {
        return this.isSelected ? 'dependent-card-selected' : 'dependent-card';
      },
      getTotalFormatted() {
        return window.formatToReal(this.dependent.balance);
      },
    },
    methods: {
      select() {
        this.isSelected = true;
        this.$el.focus();
        this.$emit('selected', this.dependent);

      },
      unselect() {
        this.isSelected = false;
      },
    },
  };
</script>

<style scoped>
  .dependent-card * {
    color: black !important;
    background-color: white !important;
  }

  .dependent-card-selected, .dependent-card-selected * {
    color: white !important;
    background-color: #00863D !important;
  }

  .v-card--link:focus:before {
    opacity: 0 !important;
  }
</style>
