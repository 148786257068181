export default {
  getDependent: state => {
    return state.dependent;
  },
  hasDependent: state => {
    return state.dependent !== null;
  },
  getProducts: state => {
    return state.products;
  },
  getTotal: state => {
    return state.products.reduce((a, b) => {
      return parseFloat(a) + parseFloat(b.price);
    }, 0);
  },
  hasAnyProduct: state => {
    return state.products.length > 0;
  },
};
