<template>
  <v-form ref="form" v-model="isValid">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="localWebserviceUri"
            :rules="rules.required"
            label="URL do servidor local"
            required
          ></v-text-field>
        </v-col>

      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="totem_token"
            label="Totem token"
            :rules="rules.required"
            required
          ></v-text-field>
        </v-col>

      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-btn class="mr-4" @click="save">Salvar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  export default {
    name: 'Config',

    data() {
      return {
        localWebserviceUri: '',
        totem_token: '',
        isValid: true,
        rules: {
          required: [
            value => !!value || 'Este campo é obrigatório!',
          ],
        }
      };
    },
    mounted() {
        const localWebserviceUri = localStorage.getItem('local_webservice_uri');
        this.localWebserviceUri = localWebserviceUri !== 'undefined' && localWebserviceUri ? JSON.parse(localWebserviceUri) : '';

        const totemToken = localStorage.getItem('totem_token');
        this.totem_token = totemToken !== 'undefined' && totemToken ? JSON.parse(totemToken) : '';
    },
    methods: {
      save() {

        this.$refs.form.validate();

        if (this.isValid === false) {
          return;
        }

        if (this.localWebserviceUri) {
          localStorage.setItem('local_webservice_uri', JSON.stringify(this.localWebserviceUri));
        }

        if (this.totem_token) {
          localStorage.setItem('totem_token', JSON.stringify(this.totem_token));
        }

        alert('salvo');
      },
    },
  };
</script>

<style scoped>

</style>
