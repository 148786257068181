<template>
  <div class="my-2 col d-flex product-card">
    <v-card outlined :id="'card-'+product.id" :raised="isSelected" :disabled="!hasStock" class="flex-fill">
      <v-card-title class="d-flex justify-center card-title">
        <h4 class="truncate-overflow">{{ product.name }}</h4>
      </v-card-title>
      <v-card-text class="text-center justify-center" style="padding: .5rem !important;">
        <v-img v-if="product.img" :src="product.img" height="180px"/>
        <v-img v-else src="../../assets/logo.png" height="180px" contain/>
        <div class="mt-2">
          {{ remaining }} restantes <br>
          {{ formatToReal(product.price) }}
        </div>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-flex>
          <v-btn color="red darken-4 icon-button" class="white--text" rounded raised @click="removeProductFromCart"
                 :disabled="quantityToBeSold === 0">
            <v-icon dark>mdi-minus-circle</v-icon>
          </v-btn>
        </v-flex>
        <v-flex>
          {{ quantityToBeSold }}
        </v-flex>
        <v-flex>
          <v-btn color="primary icon-button" class="white--text" rounded raised @click="addProductToCart"
                 :disabled="remaining === 0">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'ProductCard',
    props: {
      product: {
        type: Object,
        default: {
          id: null,
          name: null,
          img: null,
          quantity: null,
          price: null,
          category_id: null,
          category_name: null,
        },
      },
    },
    data() {
      return {
        isSelected: false,
        quantityToBeSold: 0,
      };
    },
    mounted() {
      this.setProductQuantity();
    },
    computed: {
      remaining() {
        return this.product.quantity - this.quantityToBeSold;
      },
      hasStock() {
        return this.product.quantity - this.quantityToBeSold > 0 || this.quantityToBeSold > 0;
      },
    },
    methods: {
      ...mapGetters({getProducts: 'sale/getProducts'}),
      setProductQuantity() {
        this.quantityToBeSold = this.countProductQuantityOnCart();
      },
      unselect() {
        this.isSelected = false;
      },
      addProductToCart() {
        if (this.remaining - 1 < 0) {
          this.$emit('insufficient', this.product);
          return;
        }
        this.$emit('add', this.product);
      },
      removeProductFromCart() {
        this.$emit('remove', this.product);
      },
      formatToReal(value) {
        return window.formatToReal(value);
      },
      truncateText(value) {
        return window.truncateString(value, 17);
      },
      countProductQuantityOnCart() {
        const filter = product => {
          return product.id === this.product.id;
        };
        return this.getProducts().filter(filter).length;
      },
    },
  };
</script>

<style scoped>
  .card-title {
    height: 100px;
  }

  .truncate {
    width: 1000px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .truncate-overflow {
    --lh: 2rem;
    --max-lines: 2;
    padding: .5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background:#fff;
    position:absolute;
    max-height: calc(var(--lh) * var(--max-lines));
    text-align: center;
  }

  .product-card {
    padding: 0 !important;
  }

  .v-card {
    min-width: 90% !important;
  }

  .v-card__actions .v-btn.v-btn {
    min-width: auto;
  }

  .v-card--link:focus:before {
    opacity: 0 !important;
  }

  .icon-button {
    min-width: 36px !important;
    padding: 0 !important;
  }
</style>
