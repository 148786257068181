window.writing = function(fn, delay = 1000) {
  this.temp = null;
  this.on('input', () => {
    clearTimeout(this.temp);
    this.temp = setTimeout(() => {
      fn();
    }, delay);
  });
  return this;
};

window.formatToReal = (value) => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
}).format(value);

window.basePagination = () => {
    return {
      page: 1,
      lastPage: 1,
      data: [],
      total: 0,
      perPage: 12,
    };
};

window.truncateString = (str, num) => {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

