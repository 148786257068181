<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, valid, handleSubmit }">
    <v-card class="elevation-12 mx-md-0 mx-sm-2">
      <v-toolbar dark class="gradient-background">
        <v-toolbar-title>Login</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form autocomplete="off">
          <ValidationProvider name="email" rules="required|email|max:100" v-slot="{ errors, valid }">
            <v-text-field prepend-inner-icon="fa-envelope" label="E-mail"
                          v-model="form.email" :clearable="true"
                          :outlined="true" :counter="100"
                          :success="valid" :error-messages="errors"
                          name="email"/>
          </ValidationProvider>
          <ValidationProvider name="password" rules="required|max:100" v-slot="{ errors, valid }">
            <v-text-field prepend-inner-icon="fa-key" label="Senha"
                          v-model="form.password" :clearable="true"
                          :outlined="true" :counter="100"
                          :success="valid" :error-messages="errors"
                          :append-icon="showPasswordIcon"
                          :type="passwordType"
                          @click:append="showPassword = !showPassword"
                          name="email"/>
          </ValidationProvider>
        </v-form>
        <v-alert :dense="true" :outlined="true" type="error" :value="hasErrors">
          {{ errorText }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#00863D" class="white--text btn-load" @click="handleSubmit(submit)" :disabled="invalid || waitingResponse">
          <v-icon left dark class="icon-load" v-if="waitingResponse">mdi-loading</v-icon>
          Entrar
        </v-btn>
      </v-card-actions>
    </v-card>
    <alert-dialog type="error" ref="alertDialog"/>
  </ValidationObserver>
</template>

<script>
  import {ValidationObserver, ValidationProvider} from 'vee-validate';
  import {LOGIN} from '../api-endpoints';
  import {mapActions, mapGetters} from 'vuex';
  import AlertDialog from "./AlertDialog";

  export default {
    name: 'Login',
    components: {ValidationObserver, ValidationProvider, AlertDialog},
    data() {
      return {
        form: {
          email: null,
          password: null,
        },
        showPassword: false,
        hasErrors: false,
        errorText: '',
        waitingResponse: false
      };
    },
    computed: {
      showPasswordIcon() {
        return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
      },
      passwordType() {
        return this.showPassword ? 'text' : 'password';
      },
    },
    methods: {
      ...mapActions({setUser: 'user/setUser', setToken: 'user/setToken'}),
      async submit() {
        if (this.validConfigData() === false) {
          return false;
        }
        this.hasErrors = false;
        this.waitingResponse = true;
        try {
          const response = await window.axiosUnauthenticated.post(LOGIN, this.form);
          this.setUser(response.data.user);
          this.setToken(response.data.jwt);
          this.$emit('authenticated');
        } catch (e) {
          this.hasErrors = true;
          try {
            this.errorText = e.response.data.message;
          } catch (e) {
            this.errorText = 'Whoops! Algo deu errado...';
          }
        } finally {
          this.waitingResponse = false;
        }
      },

      validConfigData() {
        return true;
      },
    },
  };
</script>

<style scoped>
  .gradient-background {
    background: rgb(255, 172, 0);
    background: linear-gradient(207deg, rgba(255, 172, 0, 1) 0%, rgba(0, 134, 61, 1) 76%, rgba(0, 134, 61, 1) 91%);
  }

  .icon-load {
    -webkit-animation: rotation 1s infinite linear;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }

  .v-text-field input {
    padding: 8px !important;
  }
</style>
